import React from "react";
import OtpInput from "react-otp-input";

interface OtpVerificationProps {
  otp: string;
  setOtp: (value: string) => void;
  handleOtpVerification: (e: React.FormEvent) => void;
}

const OtpVerification: React.FC<OtpVerificationProps> = ({
  otp,
  setOtp,
  handleOtpVerification,
}) => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#f9fafb",
      fontFamily: "'Inter', sans-serif",
    },
    formContainer: {
      maxWidth: "400px",
      width: "95%",
      backgroundColor: "#ffffff",
      // padding: "24px",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      textAlign: "center" as const,
      margin: "0 10px",
      padding: "4px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "600",
      color: "#333",
      marginBottom: "16px",
    },
    otpContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "4px",
      marginBottom: "20px",
    },
    otpInput: {
      width: "48px",
      height: "48px",
      fontSize: "16px",
      textAlign: "center" as const,
      border: "1px solid #d1d5db",
      borderRadius: "8px",
      backgroundColor: "#f9fafb",
      outline: "none",
      transition: "border-color 0.3s",
    },
    otpInputFocus: {
      borderColor: "#4caf50",
    },
    button: {
      width: "100%",
      padding: "12px",
      backgroundColor: "#4caf50",
      color: "#ffffff",
      fontSize: "16px",
      fontWeight: "600" as const,
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    buttonHover: {
      backgroundColor: "#45a049",
    },
    subtitle: {
      fontSize: "14px",
      color: "#6b7280",
      marginBottom: "16px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.heading}>Verify Your Account</h2>
        <p style={styles.subtitle}>
          We sent a 5-digit code to your email. Please enter it below to
          continue. 😀
        </p>
        <form onSubmit={handleOtpVerification}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={5}
            shouldAutoFocus
            inputType={"number"}
            containerStyle={styles.otpContainer}
            renderInput={(props) => <input {...props} />}
            inputStyle={styles.otpInput}
            // focusStyle={styles.otpInputFocus}
          />
          <button
            type="submit"
            style={styles.button}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor =
                styles.buttonHover.backgroundColor)
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor =
                styles.button.backgroundColor)
            }
          >
            Verify OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
