import { Dispatch, SetStateAction } from "react";

import { SubscriptionPlan } from "./components/SubscriptionSelection";

export const handleRegistration = async (
  e: React.FormEvent,
  API: string,
  email: string,
  setStep: Dispatch<
    SetStateAction<"registration" | "otp" | "subscription" | "download">
  >
) => {
  e.preventDefault();
  try {
    const response = await fetch(`${API}/api/otps/email/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        service: process.env.REACT_APP_USER_SERVICE,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("OTP sent successfully");
      setStep("otp");
    } else {
      throw new Error(data.message || "Failed to send OTP");
    }
  } catch (error: any) {
    console.error("OTP Send Error", error);
    alert(error.message);
  }
};

export const handleOtpVerification = async (
  e: React.FormEvent,
  API: string,
  email: string,
  firstName: string,
  lastName: string,
  otp: string,
  setStep: Dispatch<
    SetStateAction<"registration" | "otp" | "subscription" | "download">
  >
) => {
  e.preventDefault();
  try {
    const response = await fetch(`${API}/api/otps/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // credentials: "include",
      body: JSON.stringify({ email, otp, password: crypto.randomUUID() }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log(response, data.token, "set-cookie");
      if (!data.token) {
        throw new Error("Set-Cookie header missing");
      }

      // Extract JWT token from the Set-Cookie header
      const match = data.token.match(/Authorization=([^;]+);/);
      const token = match ? match[1] : null;

      if (!token) {
        throw new Error("Authorization token not found in Set-Cookie header");
      }
      localStorage.setItem(`${process.env.REACT_APP_USER_SERVICE}_jwt`, token);
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);

      console.log(token, "set-cookie");
      // Save user details after OTP verification
      const userSaveResponse = await fetch(
        `${API}/api/users/${data.data?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add the token here
          },
          body: JSON.stringify({ first_name: firstName, last_name: lastName }),
        }
      );
      console.log(userSaveResponse);
      if (userSaveResponse.ok) {
        setStep("subscription");
      } else {
        throw new Error("Failed to save user details");
      }
    } else {
      throw new Error(data.message || "OTP Verification Failed");
    }
  } catch (error: any) {
    console.error("OTP Verification Error", error);
    alert(error.message);
  }
};

export const handleSubscriptionSelection = async (
  API: string,
  plan: SubscriptionPlan,
  email: string
) => {
  try {
    const token = localStorage.getItem(
      `${process.env.REACT_APP_USER_SERVICE}_jwt`
    );
    const response = await fetch(
      `${API}/api/payments/stripe/create_checkout_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the token here
        },
        body: JSON.stringify({
          price_id: plan.id,
          service: process.env.REACT_APP_USER_SERVICE,
        }),
      }
    );

    const data = await response.json();

    console.log(data.data);

    if (response.ok && data.data) {
      console.log(data.data.url, "data:url");
      window.location.href = data.data.url;
    } else {
      throw new Error(data.message || "Failed to create checkout session");
    }
  } catch (error: any) {
    console.error("Subscription Error", error);
    alert(error.message);
  }
};
