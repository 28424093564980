import React, { useState } from "react";

export interface SubscriptionPlan {
  id: string;
  name: string;
  unit_amount: number;
  product: {
    name: string;
    images: string[];
  };
  recurring: {
    interval: "month" | "year";
  };
  discount?: string;
  description?: string;
  logoUrl?: string; // Logo for the product
  extraInfo?: string; // Additional details about the product
}

interface SubscriptionSelectionProps {
  subscriptionPlans: SubscriptionPlan[];
  handleSubscriptionSelection: (plan: SubscriptionPlan) => void;
}

const styles = {
  container: {
    maxWidth: "750px",
    margin: "0 auto",
    padding: "20px 5%",
    textAlign: "center" as const,
    fontFamily: "'Inter', sans-serif",
    backgroundColor: "#121212",
    color: "#ffffff",
    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.4)",
  },
  header: {
    marginBottom: "20px",
  },
  logo: {
    width: "80px",
    height: "80px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold" as const,
    marginBottom: "8px",
  },
  subtitle: {
    fontSize: "12px",
    color: "#b3b3b3",
  },
  plansContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "20px",
    marginTop: "20px",
  },
  planCard: {
    borderRadius: "12px",
    padding: "16px",
    backgroundColor: "#1e1e1e",
    textAlign: "center" as const,
    position: "relative" as const,
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
  },
  planCardHover: {
    transform: "scale(1.02)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
  },
  ticker: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    backgroundColor: "#ff5722",
    color: "#ffffff",
    fontSize: "12px",
    padding: "4px 8px",
    borderRadius: "8px",
    fontWeight: "bold" as const,
  },
  planName: {
    fontSize: "18px",
    fontWeight: "600" as const,
    marginBottom: "8px",
    color: "#ffffff",
  },
  planPrice: {
    fontSize: "24px",
    fontWeight: "700" as const,
    color: "#fff",
    marginBottom: "8px",
  },
  planDescription: {
    fontSize: "12px",
    color: "#b3b3b3",
    marginBottom: "12px",
  },
  extraInfo: {
    fontSize: "12px",
    color: "#a3a3a3",
    marginTop: "8px",
  },
  radioButton: {
    marginRight: "10px",
    verticalAlign: "middle" as const,
  },
  continueButton: {
    marginTop: "20px",
    backgroundColor: "#6a0dad",
    color: "#ffffff",
    padding: "18px 26px",
    borderRadius: "12px",
    border: "none",
    fontSize: "18px",
    fontWeight: "600" as const,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  continueButtonHover: {
    backgroundColor: "#540a91",
  },
  footer: {
    marginTop: "20px",
    fontSize: "12px",
    color: "#b3b3b3",
  },
};

const SubscriptionSelection: React.FC<SubscriptionSelectionProps> = ({
  subscriptionPlans,
  handleSubscriptionSelection,
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  const selectedPlan = subscriptionPlans.find(
    (plan) => plan.id === selectedPlanId
  );

  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        {subscriptionPlans[0]?.product.images.length > 0 && (
          <img
            src={subscriptionPlans[0].product.images[0]}
            alt={`${subscriptionPlans[0].product.name} logo`}
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "20%",
              objectFit: "cover",
              marginBottom: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
        <h1 style={styles.title}>Try Free for 3 days</h1>
        <p style={styles.subtitle}>Choose the plan that works best for you.</p>
      </div>

      {/* Subscription Plans */}
      <div style={styles.plansContainer}>
        {subscriptionPlans.map((plan) => (
          <div
            key={plan.id}
            style={styles.planCard}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = styles.planCardHover.transform!;
              e.currentTarget.style.boxShadow = styles.planCardHover.boxShadow!;
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "none";
              e.currentTarget.style.boxShadow = styles.planCard.boxShadow!;
            }}
          >
            <label>
              <input
                type="radio"
                name="subscriptionPlan"
                value={plan.id}
                checked={selectedPlanId === plan.id}
                onChange={() => setSelectedPlanId(plan.id)}
                style={styles.radioButton}
              />
              {plan.recurring.interval === "year" && (
                <div style={styles.ticker}>50% OFF</div>
              )}
              <h2 style={styles.planName}>{plan.name}</h2>
              <p style={styles.planPrice}>
                ${plan.unit_amount / 100} / {plan.recurring.interval}
              </p>
              <p style={styles.planDescription}>
                {plan.description || "Best value for your needs."}
              </p>
              {plan.extraInfo && (
                <p style={styles.extraInfo}>{plan.extraInfo}</p>
              )}
            </label>
          </div>
        ))}
      </div>

      {/* Continue Button */}
      <button
        style={styles.continueButton}
        onClick={() => {
          if (selectedPlan) {
            handleSubscriptionSelection(selectedPlan);
          } else {
            alert("Please select a subscription plan.");
          }
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            styles.continueButtonHover.backgroundColor!)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor =
            styles.continueButton.backgroundColor!)
        }
      >
        Continue & Start Trial
      </button>

      {/* Footer */}
      <div style={styles.footer}>Terms • Privacy • Restore Purchases</div>
    </div>
  );
};

export default SubscriptionSelection;
