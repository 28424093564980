import React from "react";
import { FaApple } from "react-icons/fa"; // Ensure you install react-icons: npm install react-icons

const DownloadPage: React.FC = () => {
  const styles = {
    container: {
      background: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ffffff",
      textAlign: "center" as const,
      padding: "20px",
    },
    card: {
      maxWidth: "400px",
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent card effect
      backdropFilter: "blur(10px)", // Glassmorphism effect
      padding: "2rem",
      borderRadius: "16px",
      boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    },
    icon: {
      fontSize: "60px",
      color: "#ffffff",
      marginBottom: "20px",
    },
    title: {
      fontSize: "1.8rem",
      fontWeight: "bold" as const,
      marginBottom: "1rem",
      color: "#ffffff",
    },
    description: {
      fontSize: "1rem",
      marginBottom: "2rem",
      lineHeight: "1.6",
      color: "#e0e0e0",
    },
    button: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#007bff",
      padding: "12px 20px",
      color: "#ffffff",
      fontSize: "1rem",
      fontWeight: "bold" as const,
      borderRadius: "30px",
      textDecoration: "none",
      transition: "all 0.3s ease",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
    },
    buttonIcon: {
      marginRight: "8px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {/* Icon */}
        <FaApple style={styles.icon} />
        {/* Title */}
        <h2 style={styles.title}>Download the App</h2>
        {/* Description */}
        <p style={styles.description}>
          Thank you for subscribing! Click the button below to download the app
          and get started with your journey.
        </p>
        {/* Button */}
        <a
          href="https://apps.apple.com/your-app-link"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.button}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              styles.buttonHover.backgroundColor)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor =
              styles.button.backgroundColor!)
          }
        >
          <FaApple style={styles.buttonIcon} />
          Download App
        </a>
      </div>
    </div>
  );
};

export default DownloadPage;
