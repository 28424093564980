import React, { useEffect, useState } from "react";

interface RegistrationFormProps {
  userData: {
    firstName: string;
    lastName: string;
    email: string;
  };
  setUserData: (data: {
    firstName: string;
    lastName: string;
    email: string;
  }) => void;
  handleRegistration: (e: React.FormEvent) => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  userData,
  setUserData,
  handleRegistration,
}) => {
  const [isReturningUser, setIsReturningUser] = useState(false);

  useEffect(() => {
    const storedFirstName = localStorage.getItem("firstName");
    const storedLastName = localStorage.getItem("lastName");

    if (storedFirstName && storedLastName) {
      setUserData({
        ...userData,
        firstName: storedFirstName,
        lastName: storedLastName,
      });
      setIsReturningUser(true);
    }
  }, [setUserData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "2rem",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          width: "100%",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "0 10px",
        }}
      >
        {/* Logo */}
        <div style={{ textAlign: "center", marginBottom: "1rem" }}>
          <img
            src="/tape_deck_1.jpg"
            alt="Logo"
            style={{ width: "160px", height: "100px" }}
          />
        </div>

        {/* Header */}
        <h2
          style={{
            fontSize: "1.5rem",
            marginBottom: "0.5rem",
            textAlign: "left",
            fontWeight: "600",
            color: "#333",
          }}
        >
          {isReturningUser
            ? `👋 Welcome Back! ${userData.firstName}`
            : "👋 Register"}
        </h2>
        <p
          style={{
            fontSize: "0.8rem",
            textAlign: "left",
            fontWeight: "bold",
            color: "#666",
            marginBottom: "1rem",
          }}
        >
          {isReturningUser
            ? "Confirm your email to continue."
            : "Sign up to access your account."}
        </p>

        {/* Form */}
        <form
          onSubmit={handleRegistration}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              htmlFor="firstName"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                color: "#666",
                marginBottom: "0.25rem",
              }}
            >
              First Name
            </label>
            <input
              type="text"
              // placeholder="First Name"
              value={userData.firstName || ""}
              onChange={(e) =>
                setUserData({ ...userData, firstName: e.target.value })
              }
              style={{
                padding: "0.75rem",
                border: "1px solid #ddd",
                borderRadius: "8px",
                fontSize: "1rem",
                outline: "none",
                backgroundColor: isReturningUser ? "#f0f0f0" : "#f7f7f7",
                transition: "border-color 0.3s",
              }}
              disabled={isReturningUser}
              onFocus={(e) => (e.target.style.borderColor = "#4a90e2")}
              onBlur={(e) => (e.target.style.borderColor = "#ddd")}
              required
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              htmlFor="lastName"
              style={{
                fontWeight: "bold",
                fontSize: "0.8rem",
                color: "#666",
                marginBottom: "0.25rem",
              }}
            >
              Last Name
            </label>
            <input
              type="text"
              // placeholder="Last Name"
              value={userData.lastName || ""}
              onChange={(e) =>
                setUserData({ ...userData, lastName: e.target.value })
              }
              style={{
                padding: "0.75rem",
                border: "1px solid #ddd",
                borderRadius: "8px",
                fontSize: "1rem",
                outline: "none",
                backgroundColor: isReturningUser ? "#f0f0f0" : "#f7f7f7",
                transition: "border-color 0.3s",
              }}
              disabled={isReturningUser}
              onFocus={(e) => (e.target.style.borderColor = "#4a90e2")}
              onBlur={(e) => (e.target.style.borderColor = "#ddd")}
              required
            />
          </div>
          </div>

          <input
            type="email"
            placeholder="Email"
            value={userData.email || ""}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            style={{
              padding: "0.75rem",
              border: "1px solid #ddd",
              borderRadius: "8px",
              fontSize: "1rem",
              outline: "none",
              backgroundColor: "#f7f7f7",
              transition: "border-color 0.3s",
            }}
            onFocus={(e) => (e.target.style.borderColor = "#4a90e2")}
            onBlur={(e) => (e.target.style.borderColor = "#ddd")}
            required
          />
          <button
            type="submit"
            style={{
              padding: "0.75rem",
              backgroundColor: "#000",
              color: "#fff",
              border: "none",
              borderRadius: "8px",
              fontSize: "1rem",
              fontWeight: "600",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.backgroundColor = "#333")
            }
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#000")}
          >
            {isReturningUser ? "Confirm Email" : "Sign up "}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
