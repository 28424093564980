import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Registration from "./components/Registration";
import OtpVerification from "./components/OTPVerification";
import SubscriptionSelection from "./components/SubscriptionSelection";
import DownloadPage from "./components/DownloadPage";
import CancelPage from "./pages/cancelPage";
import axios from "axios";
import {
  handleOtpVerification,
  handleRegistration,
  handleSubscriptionSelection,
} from "./appHandlers";
import { SubscriptionPlan } from "./components/SubscriptionSelection";
interface UserRegistrationData {
  firstName: string;
  lastName: string;
  email: string;
}

const MultiStepFlow: React.FC = () => {
  const navigate = useNavigate();
  const API = process.env.REACT_APP_API_URL || "http://localhost:3005";
  const [step, setStep] = useState<
    "registration" | "otp" | "subscription" | "download"
  >("registration");
  const [userData, setUserData] = useState<UserRegistrationData>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [otp, setOtp] = useState<string>("");

  useEffect(() => {
    const checkUserStatus = async () => {
      const token = localStorage.getItem(
        `${process.env.REACT_APP_USER_SERVICE}_jwt`
      );

      if (!token) {
        setStep("registration");
        return;
      }

      try {
        const response = await fetch(`${API}/api/me`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Invalid or expired token.");
        }

        const user = await response.json();

        if (
          user.data?.user_service?.subscription_status === "active" &&
          user.data?.user_service?.subscription_status === "paid"
        ) {
          navigate("/downloads");
        } else {
          setStep("subscription");
        }
      } catch (error) {
        console.error("Error checking user status:", error);
        setStep("registration");
      }
    };

    checkUserStatus();
  }, [API, navigate]);

  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const token = localStorage.getItem(
          `${process.env.REACT_APP_USER_SERVICE}_jwt`
        );

        if (!token) {
          setStep("registration");
          return;
        }

        const response = await fetch(
          `${API}/api/payments/stripe/subscription_plans?service=${process.env.REACT_APP_USER_SERVICE}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        setSubscriptionPlans(data.data);
      } catch (error) {
        console.error("Failed to fetch subscription plans", error);
      }
    };

    fetchSubscriptionPlans();
  }, [step, API]);

  const renderCurrentStep = () => {
    switch (step) {
      case "registration":
        return (
          <Registration
            userData={userData}
            setUserData={setUserData}
            handleRegistration={(e) =>
              handleRegistration(e, API, userData.email, setStep)
            }
          />
        );
      case "otp":
        return (
          <OtpVerification
            otp={otp}
            setOtp={setOtp}
            handleOtpVerification={(e) =>
              handleOtpVerification(
                e,
                API,
                userData.email,
                userData.firstName,
                userData.lastName,
                otp,
                setStep
              )
            }
          />
        );
      case "subscription":
        return (
          <SubscriptionSelection
            subscriptionPlans={subscriptionPlans}
            handleSubscriptionSelection={(plan) =>
              handleSubscriptionSelection(API, plan, userData.email)
            }
          />
        );
      case "download":
        return <DownloadPage />;
      default:
        return null;
    }
  };

  return <div className="container mx-auto px-4">{renderCurrentStep()}</div>;
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MultiStepFlow />} />
        <Route path="/downloads" element={<DownloadPage />} />
        <Route path="/cancel" element={<CancelPage />} />
      </Routes>
    </Router>
  );
};

export default App;
