import React from "react";
import { Link } from "react-router-dom";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f9f9f9",
    fontFamily: "'Inter', sans-serif",
    color: "#333",
  },
  card: {
    backgroundColor: "#ffffff",
    padding: "2rem",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    textAlign: "center" as const,
    maxWidth: "400px",
    width: "100%",
  },
  title: {
    fontSize: "1.75rem",
    fontWeight: "600",
    marginBottom: "1rem",
    color: "#e63946", // A light red to indicate cancellation
  },
  description: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "1.5rem",
    lineHeight: "1.6",
  },
  link: {
    display: "inline-block",
    marginTop: "1rem",
    color: "#007bff",
    textDecoration: "none",
    fontWeight: "600" as const,
    padding: "10px 20px",
    borderRadius: "8px",
    backgroundColor: "#f0f0f0",
    transition: "background-color 0.3s ease",
  },
  linkHover: {
    backgroundColor: "#e0e0e0",
  },
};

const CancelPage: React.FC = () => {
  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>Payment Canceled</h1>
        <p style={styles.description}>
          It seems like you canceled the payment. If this was a mistake, you can
          try again or explore other options on our site.
        </p>
        <Link
          to="/"
          style={styles.link}
          onMouseEnter={(e) => {
            (e.target as HTMLElement).style.backgroundColor =
              styles.linkHover.backgroundColor!;
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLElement).style.backgroundColor =
              styles.link.backgroundColor!;
          }}
        >
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default CancelPage;
